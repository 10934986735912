<template>
	<el-table class="jscx-table" height="auto" ref="multipleTable" :data="showData" @select="pinSelect"
		@sort-change="sortChange" size="mini" border :summary-method="getSummaries" show-summary
		:row-class-name="tableRowClassName" @row-click="onRowClick">
		<el-table-column v-for="tableColumn, index in tableColumns" :label="tableColumn.label" :prop="tableColumn.prop"
			min-width="180" align="center" :sortable="tableColumn.sortable" :formatter="cellFormatter">
			<template  slot-scope="scope">
				<template v-if="scope.column.property==='domainName'">
					<a class="detail-info-link" :href="'/jscx/terms?domainNames='+scope.row[scope.column.property] + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo" target="_blank">
						{{scope.row[scope.column.property]}}
					</a>
					<!-- <el-dropdown>
					  <span class="el-dropdown-link domain-name">
					    {{scope.row[scope.column.property]}}
					  </span>
					  <el-dropdown-menu slot="dropdown">
					    <el-dropdown-item>
							<a class="detail-info-link" :href="'/jscx/terms?domainNames='+scope.row[scope.column.property] + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo" target="_blank"><small>Terms</small></a>
						</el-dropdown-item>
					    <el-dropdown-item>
							<a class="detail-info-link" :href="'/jscx/est-watch?domainNames='+scope.row[scope.column.property] + '&dateFrom=' + dateFrom + '&dateTo=' + dateTo" target="_blank"><small>Est</small></a>
						</el-dropdown-item>
					  </el-dropdown-menu>
					</el-dropdown> -->
				</template>
				<template v-else>
					{{cellFormatter(scope.row, scope.column, scope.row[scope.column.property], scope.$index)}}
				</template>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import Moment from 'moment'
	import {
		mapGetters
	} from 'vuex'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			onRowClick: {
				type: Function,
				default: null
			},
			tableData: {
				type: Array,
				default: []
			},
			tableColumns: {
				type: Array,
				default: []
			},
			selectedRows: {
				type: Array,
				default: []
			},
			dateFrom: {
				type: String,
				default: ''
			},
			dateTo: {
				type: String,
				default: ''
			},
			currentPage: {
				type: Number,
				default: 1
			},
			pageSize: {
				type: Number,
				default: 50
			}
		},
		computed: {
			sortedData() {
				const {order, prop} = this.sort
				if (order === 'ascending' && prop !== null) {
					return this.tableData.slice().sort((a, b) => a[prop] - b[prop])
				} else if (order === 'descending' && prop !== null) {
					return this.tableData.slice().sort((a, b) => b[prop] - a[prop])
				}
				return this.tableData.slice()
			},
			showData() {
				return this.sortedData.slice((this.currentPage-1)*this.pageSize, this.currentPage* this.pageSize)
			}
		},
		data() {
			return {
				sort: {
					prop: null,
					order: null
				}
			}
		},
		methods: {
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (this.selectedRows.indexOf(row) !== -1) {
					return 'highlight-row'
				}
				if (row.profit > 0) {
					return 'win-row'
				} else if (row.profit < 0) {
					return 'lose-row'
				}
				return '';
			},
			doLayout() {
				this.$refs.multipleTable.doLayout()
			},
			/*
			cpc = cost / conversion,
			ads-cpc = cost / ads-conversion
			epc = estimatedRevenue / ads-conversion
			*/
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					const values = this.tableData.map(item => Number(item[column.property]));
					// const values = data.map(item => Number(item[column.property]));
					const summary = this.tableColumns[index].summary
					if (summary) {
						if (column.property === 'cpc') {
							const cost = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['cost'])
							}, 0)
							const conversion = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['conversion'])
							}, 0)
							sums[index] = conversion > 0 ? cost / conversion : 0
						} else if (column.property === 'epc') {
							const estimatedRevenue = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['estimatedRevenue'])
							}, 0)
							const conversion = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['adsConversion'])
							}, 0)
							sums[index] = conversion > 0 ? estimatedRevenue / conversion : 0
						} else if (column.property === 'adsCpc') { 
							const cost = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['cost'])
							}, 0)
							const conversion = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['adsConversion'])
							}, 0)
							sums[index] = conversion > 0 ? cost / conversion : 0
						} else if (column.property === 'profitRate') {
							const estimatedRevenue = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['estimatedRevenue'])
							}, 0)
							const cost = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['cost'])
							}, 0)
							sums[index] = cost > 0 ? (estimatedRevenue - cost) / cost : 0
						} else if (column.property === 'roi') {
							const estimatedRevenue = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['estimatedRevenue'])
							}, 0)
							const cost = this.tableData.reduce((prev, curr) => {
								return prev + Number(curr['cost'])
							}, 0)
							sums[index] = cost > 0 ? estimatedRevenue / cost : 0
						} else {
							sums[index] = values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0);
						}
						if (column.property === 'clicks' || column.property === 'conversion') {
							sums[index] = sums[index].toFixed(0);
						} 
						else if (column.property === 'profitRate') {
							sums[index] = (sums[index] * 100).toFixed(2) + '%';
						}
						else {
							sums[index] = sums[index].toFixed(2);
						}
					} else {
						sums[index] = '-';
					}
				});
				return sums;
			},
			cellFormatter(row, column, cellValue, index) {
				if (/profitRate/i.test(column.property)) {
					return (cellValue * 100).toFixed(2) + '%'
					// return (Number(cellValue) * 100).toFixed(2) + '%'
				} else if (/ctr/i.test(column.property)) {
					return (Number(cellValue)*100).toFixed(2) + '%'
				} else if (/(budget)|(cost)|(profit)|(impressions)|(bid)|(cpc)|(epc)|(estimatedRevenue)/i.test(column.property)) {
					return Number(cellValue).toFixed(2)
				} else if (/(clicks)|(conversion)/i.test(column.property)) {
					return Number(cellValue).toFixed(0)
				} else if (/direction/i.test(column.property)) {
					return cellValue || 'Others'
				} else if (/roi/i.test(column.property)) {
					return Number(cellValue).toFixed(2)
				} else if (/(createtime)|(updatetime)/i.test(column.property)) {
					return Moment(cellValue).format('YYYY-MM-DD hh:mm:ss')
				}
				return cellValue
			},
			// 自定义百分比列的排序方法
			clearSort() {
				this.$refs.multipleTable.clearSort()
			},
			sortChange({column, prop, order}) {
				this.sort.prop = prop
				this.sort.order = order
				console.log(column, prop, order)
			}
		}
	}
</script>

<style>
	.jscx-table.el-table .lose-row {
		background: #FCEDEB;
	}

	.jscx-table.el-table .win-row {
		background: #f0f9eb;
	}
	
	.jscx-table.el-table .highlight-row {
	  background-color: rgb(217, 236, 255);
	}
	.jscx-table.el-table .el-table__body tr:hover > td.el-table__cell {
		background-color: unset;
	}
	.jscx-table.el-table th.el-table__cell > .cell {
		text-transform: capitalize;
	}
	.el-dropdown-link.domain-name {
		cursor: pointer;
	}
	a.detail-info-link:hover {
		text-decoration: none;
	}
</style>